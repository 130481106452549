export const contentContainer = () => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
});

export const slideButton = () => ({
  width: 'max-content',
  maxWidth: 'calc(100vw - 120px)'
});
