import React from 'react';
import Radium from 'radium';
import * as styles from '../styles/card.styles';

const Card = ({ content }) => {
  return (
    <div className="bg-gray-800 rounded-lg overflow-hidden">
      <img src={content.image.url} alt={content.image.title} style={styles.cardImage()} />
      <div className="p-5">
        <h1 className="text-2xl font-bold text-white py-2 uppercase">{content.title}</h1>
        <p className="text-sm text-gray-300">{content.description}</p>
        <a href={content.button.path} className="py-2 px-3 mt-4 px-6 text-white bg-blue inline-block rounded uppercase">
          {content.button.title}
        </a>
      </div>
    </div>
  );
};

export default Radium(Card);
