import React, { useState } from 'react';
import Radium from 'radium';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { BsChevronLeft as ArrowLeft, BsChevronRight as ArrowRight } from 'react-icons/bs';
import { Carousel as ReactCarousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import * as styles from '../styles/carousel.styles';
import CarouselSlide from './carousel-slide';
import { isAndAbove } from '../util';

const Carousel = ({ slides, breakpoints }) => {
  const numberOfSlides = 4;
  const [currentSlide, setCurrentSlide] = useState(0);

  const next = () => {
    if (currentSlide === numberOfSlides - 1) {
      setCurrentSlide(0);
    } else {
      setCurrentSlide(prev => prev + 1);
    }
  };

  const prev = () => {
    if (currentSlide === 0) {
      setCurrentSlide(numberOfSlides - 1);
    } else {
      setCurrentSlide(prev => prev - 1);
    }
  };

  const updateCurrentSlide = index => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  let arrowSize = 40;
  if (isAndAbove('md', breakpoints)) {
    arrowSize = 50;
  }

  return (
    <ReactCarousel
      className="carousel-wrapper"
      autoPlay
      infiniteLoop
      interval={5000}
      transitionTime={800}
      stopOnHover={false}
      showThumbs={false}
      showStatus={false}
      selectedItem={currentSlide}
      onChange={updateCurrentSlide}
      renderArrowPrev={(onClickHandler, hasPrev, label) => (
        <ArrowLeft onClick={prev} title={label} style={{ ...styles.arrow(arrowSize), left: 15 }} />
      )}
      renderArrowNext={(onClickHandler, hasNext, label) => (
        <ArrowRight onClick={next} title={label} style={{ ...styles.arrow(arrowSize), right: 15 }} />
      )}>
      {slides.map((slide, index) => (
        <CarouselSlide key={index} content={slide} breakpoints={breakpoints} />
      ))}
    </ReactCarousel>
  );
};

export default Radium(Carousel);
