import React, { useState, useEffect, useRef } from 'react';
import Radium from 'radium';
import { useKeenSlider } from 'keen-slider/react';
import * as styles from '../styles/fade-slider.styles';

const FadeSlider = ({ slides, height, pauseOnHover, rounded }) => {
  const [opacities, setOpacities] = useState([]);
  const [pause, setPause] = useState(false);
  const [currentSlide, setCurrentSlide] = useState({});
  const timer = useRef();

  const [sliderRef, slider] = useKeenSlider({
    slides: slides.length,
    loop: true,
    duration: 6000,
    move(s) {
      const new_opacities = s.details().positions.map(slide => slide.portion);
      setOpacities(new_opacities);
    },
    dragStart: () => {
      setPause(true);
    },
    dragEnd: () => {
      setPause(false);
    },
    slideChanged: s => {
      const slideIndex = s.details().relativeSlide;
      setCurrentSlide(slides[slideIndex]);
    }
  });

  const startSliderInterval = () => {
    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.next();
      }
    }, 5000);
  };

  const stopSliderInterval = () => {
    clearInterval(timer.current);
  };

  const resetSliderInterval = () => {
    stopSliderInterval();
    startSliderInterval();
  };

  useEffect(() => {
    if (pauseOnHover) {
      sliderRef.current.addEventListener('mouseover', () => {
        setPause(true);
      });
      sliderRef.current.addEventListener('mouseout', () => {
        setPause(false);
      });
    }
  }, [sliderRef]);

  useEffect(() => {
    startSliderInterval();
    return () => {
      stopSliderInterval();
    };
  }, [pause, slider]);

  const onPrevious = event => {
    event.stopPropagation() || slider.prev();
    resetSliderInterval();
  };

  const onNext = event => {
    event.stopPropagation() || slider.next();
    resetSliderInterval();
  };

  const handleClick = () => {
    if (currentSlide.link) {
      window.open(currentSlide.link, '_blank');
    }
  };

  return (
    <div style={styles.fader(height, rounded)}>
      <div ref={sliderRef}>
        {slides.map((slide, index) => (
          <div key={index} style={styles.faderSlide(opacities[index], slide.image.file.url)} onClick={handleClick}>
            {slide.artist && slide.title && (
              <div
                className="absolute bg-white px-4 py-3"
                style={{ left: 24, bottom: 24, maxWidth: 'calc(100% - 48px)' }}>
                <div className="text-blue text-xl sm:text-3xl uppercase font-bold tracking-wider">{slide.artist}</div>
                <div className="sm:text-lg uppercase">
                  <span className="font-bold italic">{slide.title}</span> AVAILABLE NOW
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      {slider && (
        <>
          <ArrowLeft onClick={onPrevious} disabled={currentSlide === 0} />
          <ArrowRight onClick={onNext} disabled={currentSlide === slider.details().size - 1} />
        </>
      )}
    </div>
  );
};

const ArrowLeft = props => {
  const disabled = props.disabled ? ' arrow--disabled' : '';
  return (
    <svg
      onClick={props.onClick}
      className={'arrow arrow--left' + disabled}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24">
      <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
    </svg>
  );
};

const ArrowRight = props => {
  const disabled = props.disabled ? ' arrow--disabled' : '';
  return (
    <svg
      onClick={props.onClick}
      className={'arrow arrow--right' + disabled}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24">
      <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
    </svg>
  );
};

export default Radium(FadeSlider);
