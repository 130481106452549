import React from 'react';
import Radium from 'radium';
import { Link } from 'gatsby';
import Button from './button';
import * as styles from '../styles/carousel-slide.styles';
import { isAndAbove } from '../util';

const CarouselSlide = ({ content, breakpoints }) => {
  return (
    <div className="h-full">
      <img className="h-full object-cover" src={content.image.file.url} alt={content.image.title} />
      <div className="text-center text-white" style={styles.contentContainer()}>
        <img src={content.icon.file.url} alt={content.icon.title} style={{ width: 75 }} />
        <div className="font-bold xs:text-2xl sm:text-3xl md:text-4xl tracking-wider mt-3 mb-1 uppercase">
          {content.title}
        </div>
        <div className="xs:text-sm sm:text-base md:text-xl tracking-widest uppercase">{content.subTitle}</div>
        <Link to={content.buttonPath}>
          <Button
            className="my-8"
            text={content.buttonText}
            size={isAndAbove('md', breakpoints) && 'xl'}
            style={styles.slideButton()}
          />
        </Link>
      </div>
    </div>
  );
};

export default Radium(CarouselSlide);
