import React, { useState } from 'react';
import Radium from 'radium';
import { graphql, Link } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import get from 'lodash/get';
import { Fade } from 'react-reveal';
import { ImArrowRight2 as ArrowRight } from 'react-icons/im';
import {
  FaEnvelope as EmailIcon,
  FaInstagram as InstagramIcon,
  FaFacebookF as FacebookIcon,
  FaTwitter as TwitterIcon,
  FaYoutube as YoutubeIcon
} from 'react-icons/fa';
import ParticleImage, { Vector, forces } from 'react-particle-image';

import Layout from '../components/layout';
import * as styles from '../styles/home.styles';

import SEO from '../components/seo';
import Button from '../components/button';
import OutlineButton from '../components/outline-button';
import Carousel from '../components/carousel';
import Card from '../components/card';
import FadeSlider from '../components/fade-slider';
import Map from '../components/map';
import Footer from '../components/footer';
import { useSocialMedia } from '../hooks/use-social-media';

const RootIndex = props => {
  const carouselSlides = get(props, 'data.allContentfulHomePageCarouselSlide.nodes');
  const services = get(props, 'data.allContentfulService.nodes');
  const newReleases = get(props, 'data.allContentfulNewRelease.nodes');

  const socials = useSocialMedia();

  const breakpoints = useBreakpoint();

  const particleOptions = {
    filter: ({ x, y, image }) => {
      const pixel = image.get(x, y);
      return pixel.b > 50;
    },
    color: ({ x, y, image }) => '#0D63A9',
    radius: () => Math.random() * 1.5 + 0.5,
    mass: () => 40,
    friction: () => 0.15,
    initialPosition: ({ canvasDimensions }) => {
      return new Vector(canvasDimensions.width / 2, canvasDimensions.height / 2);
    }
  };

  const motionForce = (x, y) => {
    return forces.disturbance(x, y, 5);
  };

  const serviceCards = services.map(s => ({
    title: s.title,
    description: s.descriptionOnHomePage,
    image: {
      title: s.imageOnHomePage.title,
      url: s.imageOnHomePage.file.url
    },
    button: {
      title: s.buttonTitle,
      path: s.buttonPath
    }
  }));

  return (
    <Layout location={props.location} breakpoints={breakpoints}>
      <SEO title="Home" />
      <div className="h-screen relative" style={{ minHeight: '35rem' }}>
        <Carousel slides={carouselSlides} breakpoints={breakpoints} />
        {/*<div className="absolute text-white font-bold" style={styles.title()}>*/}
        {/*  {title}*/}
        {/*</div>*/}
        {/*<div className="flex absolute bottom-0 bg-blue text-white w-full py-6 px-24">*/}
        {/*  <div className="mr-12 font-bold">*/}
        {/*    <div className="text-gray-400 mb-1">NEW MUSIC</div>*/}
        {/*    <div className="flex items-center">*/}
        {/*      Check our Spotify Playlist <ArrowRight className="ml-2" />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <div className="mr-12 font-bold">*/}
        {/*    <div className="text-gray-400 mb-1">LATEST NEWS</div>*/}
        {/*    <div className="flex items-center">*/}
        {/*      Evan Khay's New Single Release <ArrowRight className="ml-2" />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="h-20 flex justify-center items-center absolute bottom-0 bg-blue text-white w-full">
          <Fade delay={1000}>
            <a href={`mailto:${socials.email}`}>
              <EmailIcon style={{ height: 30, width: 30, margin: '0 1rem' }} />
            </a>
          </Fade>
          <Fade delay={1200}>
            <a href={socials.instagram} target="_blank">
              <InstagramIcon style={{ height: 30, width: 30, margin: '0 1rem' }} />
            </a>
          </Fade>
          <Fade delay={1400}>
            <a href={socials.facebook} target="_blank">
              <FacebookIcon style={{ height: 30, width: 30, margin: '0 1rem' }} />
            </a>
          </Fade>
          <Fade delay={1600}>
            <a href={socials.twitter} target="_blank">
              <TwitterIcon style={{ height: 30, width: 30, margin: '0 1rem' }} />
            </a>
          </Fade>
          <Fade delay={1800}>
            <a href={socials.youTube} target="_blank">
              <YoutubeIcon style={{ height: 30, width: 30, margin: '0 1rem' }} />
            </a>
          </Fade>
        </div>
      </div>
      <div className="bg-gray-900">
        <div className="m-auto px-6 pb-24" style={{ maxWidth: 1536 }}>
          {!breakpoints.xs && (
            <div className="flex justify-center relative">
              <ParticleImage
                src={'https://zeomusic-assets.s3-us-west-2.amazonaws.com/zm_logo_blue.png'}
                width={400}
                height={400}
                scale={0.07}
                entropy={20}
                maxParticles={4000}
                particleOptions={particleOptions}
                mouseMoveForce={motionForce}
                touchMoveForce={motionForce}
                backgroundColor="transparent"
              />
            </div>
          )}
          <Fade>
            <div className="text-center mb-16 xs:pt-12 sm:-mt-12">
              <h2 className="text-base text-blue font-semibold tracking-wide uppercase">Aloha, we're</h2>
              <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl">ZEO MUSIC</p>
              <p className="mt-4 max-w-2xl text-lg sm:text-xl text-gray-400 lg:mx-auto">
                A production company with almost 20 years of music production and engineering experience. Located in the
                heart of Honolulu, Hawai‘i, Zeo Music’s recording studio is capable of capturing the most ideal version
                of your music.
              </p>
            </div>
          </Fade>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 m-auto max-w-5xl">
            {serviceCards.map((card, index) => (
              <div key={index} className="flex justify-center">
                <div className="flex-grow">
                  <Fade bottom delay={300} distance="7rem">
                    <Card content={card} />
                  </Fade>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width="100%"
        height="100"
        viewBox="0 0 100 100"
        preserveAspectRatio="none">
        <path fill="#111827" stroke="#111827" d="M0 0 C 50 100 80 100 100 0 Z" />
      </svg>
      <div className="m-auto px-6 py-12 sm:py-24" style={{ maxWidth: 1536 }}>
        <div className="text-center mb-12">
          <h2 className="text-base text-blue font-semibold tracking-wide uppercase">Check it out</h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-4xl">NEW RELEASES</p>
          <Link to={'/news'}>
            <Button className="mt-4" text="SEE ALL" />
          </Link>
        </div>
        <FadeSlider slides={newReleases} height={500} pauseOnHover rounded />
      </div>
      <div className="bg-gray-900">
        <div
          className="m-auto px-6 pt-12 md:pt-24 pb-8 flex flex-wrap justify-center items-center"
          style={{ maxWidth: 1536 }}>
          <div className="flex flex-col justify-center items-center text-center px-12 md:px-24 py-12">
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl">GET STARTED</p>
            <p className="mt-4 max-w-2xl text-xl text-gray-400 lg:mx-auto">
              Contact us today to get started on your next project.
            </p>
            <Link to={'/contact'}>
              <Button className="mt-4" text="BOOK NOW" />
            </Link>
          </div>
          <div className="flex-grow" style={{ minWidth: breakpoints.xs ? 250 : 450 }}>
            <Map height="350px" />
          </div>
        </div>
      </div>
      <Footer breakpoints={breakpoints} />
    </Layout>
  );
};

export default Radium(RootIndex);

export const pageQuery = graphql`
  query RootIndexQuery {
    contentfulHeroSection(slug: { eq: "home" }) {
      title
      heroImage {
        title
        file {
          url
        }
      }
    }
    allContentfulHomePageCarouselSlide(sort: { fields: order }) {
      nodes {
        title
        subTitle
        image {
          title
          file {
            url
          }
        }
        icon {
          title
          file {
            url
          }
        }
        buttonText
        buttonPath
      }
    }
    allContentfulService(sort: { fields: order }) {
      nodes {
        title
        descriptionOnHomePage
        imageOnHomePage {
          title
          file {
            url
          }
        }
        buttonTitle
        buttonPath
      }
    }
    allContentfulNewRelease(sort: { fields: order }) {
      nodes {
        title
        artist
        link
        image {
          file {
            url
          }
        }
      }
    }
  }
`;
