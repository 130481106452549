export const fader = (height, rounded) => ({
  height: height,
  position: 'relative',
  overflow: 'hidden',
  borderRadius: rounded && '0.5rem'
});

export const faderSlide = (opacity, backgroundImage) => ({
  opacity: opacity,
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  background: `url(${backgroundImage}) no-repeat center center/cover`,
  cursor: 'pointer'
});
