import React from 'react';
import Radium from 'radium';
import GoogleMapReact from 'google-map-react';
import { FaMapMarkerAlt as MapMarker } from 'react-icons/fa';
import * as styles from '../styles/map.styles';

const LocationPin = ({ text }) => {
  const onMarkerClick = () => {
    const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      '4211 Waialae Avenue #1030, Honolulu, HI 96816, USA'
    )}`;
    window.open(url, '_blank');
  };

  return (
    <div className="pin">
      <MapMarker
        className="animate-bounce text-blue cursor-pointer"
        style={{ width: 50, height: 50 }}
        onClick={onMarkerClick}
      />
      {/*<p className="pin-text">{text}</p>*/}
    </div>
  );
};

const Map = ({ height }) => {
  return (
    <div style={styles.mapContainer(height)}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAPS_API_KEY }}
        defaultCenter={{
          address: '4211 Waialae Avenue #1030, Honolulu, HI 96816, USA',
          lat: 21.277093,
          lng: -157.786374
        }}
        defaultZoom={14}
        options={{
          styles: [
            {
              featureType: 'landscape.natural',
              stylers: [
                {
                  color: '#bcddff'
                }
              ]
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry.fill',
              stylers: [
                {
                  color: '#5fb3ff'
                }
              ]
            },
            {
              featureType: 'road.arterial',
              stylers: [
                {
                  color: '#ebf4ff'
                }
              ]
            },
            {
              featureType: 'road.local',
              elementType: 'geometry.fill',
              stylers: [
                {
                  color: '#ebf4ff'
                }
              ]
            },
            {
              featureType: 'road.local',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  visibility: 'on'
                },
                {
                  color: '#93c8ff'
                }
              ]
            },
            {
              featureType: 'landscape.man_made',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#c7e2ff'
                }
              ]
            },
            {
              featureType: 'transit.station.airport',
              elementType: 'geometry',
              stylers: [
                {
                  saturation: 100
                },
                {
                  gamma: 0.82
                },
                {
                  hue: '#0088ff'
                }
              ]
            },
            {
              elementType: 'labels.text.fill',
              stylers: [
                {
                  color: '#1673cb'
                }
              ]
            },
            {
              featureType: 'road.highway',
              elementType: 'labels.icon',
              stylers: [
                {
                  saturation: 58
                },
                {
                  hue: '#006eff'
                }
              ]
            },
            {
              featureType: 'poi',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#4797e0'
                }
              ]
            },
            {
              featureType: 'poi.park',
              elementType: 'geometry',
              stylers: [
                {
                  color: '#209ee1'
                },
                {
                  lightness: 49
                }
              ]
            },
            {
              featureType: 'transit.line',
              elementType: 'geometry.fill',
              stylers: [
                {
                  color: '#83befc'
                }
              ]
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  color: '#3ea3ff'
                }
              ]
            },
            {
              featureType: 'administrative',
              elementType: 'geometry.stroke',
              stylers: [
                {
                  saturation: 86
                },
                {
                  hue: '#0077ff'
                },
                {
                  weight: 0.8
                }
              ]
            },
            {
              elementType: 'labels.icon',
              stylers: [
                {
                  hue: '#0066ff'
                },
                {
                  weight: 1.9
                }
              ]
            },
            {
              featureType: 'poi',
              elementType: 'geometry.fill',
              stylers: [
                {
                  hue: '#0077ff'
                },
                {
                  saturation: -7
                },
                {
                  lightness: 24
                }
              ]
            }
          ]
        }}>
        <LocationPin lat={21.277093} lng={-157.786374} text="Zeo Music" />
      </GoogleMapReact>
    </div>
  );
};

export default Radium(Map);
